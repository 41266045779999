*, html, body {
  font-family: 'Lexend', 'Lora', 'sans-serif';
}

.logo {
  margin-left: -14px;
  margin-top: -3px;
  width: 150px;
}

.site-layout {
  background-color: #ffff;
}

.site-layout-background {
  background-color: #ffff;
}

Footer {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: antiquewhite;
}

.shadow-card {
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 10;
}

.ant-typography {
  color: #193156;
}

.user-name-text .ant-menu-title-content {
  color: rgba(255, 255, 255, 0.65) !important;
}

